










































import _ from 'lodash'
import { Component, Prop, Vue } from 'vue-property-decorator'
import Avatar from '../../components/Avatar/index.vue'
import MembershipBadge from '../../components/MembershipBadge/index.vue'

type Item = {
  children: Item[]
}

@Component({
  name: 'ItemTree',
  components: {
    Avatar,
    // eslint-disable-next-line no-use-before-define
    ItemTree,
    MembershipBadge,
  },
})
export default class ItemTree extends Vue {
  @Prop({ required: true })
  readonly value: Item[]

  title(item) {
    return item.state === 'DRAFT' ? `[DRAFT] ${item.title}` : item.title
  }

  sortByTitle(list) {
    return _.orderBy(list, ['title'], ['asc'])
  }

  toggleOpen(entity) {
    this.changeOpen(current => (
      current.uri === entity.uri ? !current.open : current.open
    ))
  }

  changeOpen(f) {
    this.$emit('input', this.value.map(c => ({
      ...c,
      open: f(c),
      children: c.children.map(d => ({
        ...d,
        open: f(d),
      })),
    })))
  }
}
