













import _ from 'lodash'
import { Component, Vue, Watch } from 'vue-property-decorator'

@Component
export default class SearchField extends Vue {
  q: string = ''

  submit() {
    if (this.q.length > 0) {
      this.$router.push(`/search?q=${this.q}`)
    }
  }

  created(): void {
    this.setQ()
  }

  @Watch('$route')
  setQ() {
    this.q = _.get(this.$route, 'query.q', '')
  }
}
