


















































import * as $rdf from 'rdflib'
import { Component, Prop, Vue } from 'vue-property-decorator'
import PrismEditor from 'vue-prism-editor'
import FormRenderer from '@/components/ShaclForm/FormRenderer.vue'
import StatusFlash from '@/components/StatusFlash/index.vue'
import Status from '@/utils/Status'
import { FormShape, SHACLFormParser } from '@/components/ShaclForm/Parser/SHACLFormParser'
import * as formData from '@/components/ShaclForm/formData'
import { ValidationReport } from '@/components/ShaclForm/Parser/ValidationReport'


@Component({
  components: {
    FormRenderer,
    PrismEditor,
    StatusFlash,
  },
})
export default class ShaclForm extends Vue {
  @Prop({ required: true })
  readonly shacl: string

  @Prop({ required: true })
  readonly rdf: $rdf.IndexedFormula

  @Prop({ required: true })
  readonly subject: string

  @Prop({ required: true })
  readonly targetClasses: $rdf.ValueType[]

  @Prop({ required: true })
  readonly validationReport: ValidationReport

  @Prop({ required: true })
  readonly submitStatus: Status

  @Prop({ required: false, default: false })
  readonly fillDefaults: boolean

  form: FormShape

  data: any = {
    subject: this.subject,
    data: {},
  }

  text: any = ''

  turtle: any = null

  status: Status = new Status()

  created() {
    try {
      const parser = new SHACLFormParser(this.shacl)
      this.form = parser.parse(this.targetClasses)
      this.data = formData.fromRdf(
        this.form,
        $rdf.namedNode(this.subject),
        this.rdf,
        this.fillDefaults,
      )
      this.status.setDone()
    } catch (error) {
      this.status.setError('The form configuration is not valid.')
    }
  }

  onInput() {
    this.turtle = formData.toRdf(this.rdf, this.data, this.subject, this.form)
  }

  onSubmit() {
    this.$emit('submit', this.turtle)
  }
}
